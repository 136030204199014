import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

// ================ Reducer ================ //

const initialState = {};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
  }
}

// ================ Action creators ================ //

// ================ Thunks ================ //

export const loadData = () => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(fetchCurrentUser())]);
};
