/**
 * Returns an array of wishlist items associated with a user's profile.
 * @param {object} currentUser - The user object.
 * @returns {array} An array of wishlist items, or an empty array if input is invalid or wishlist is missing.
 */
export const getWishlist = currentUser => {
  return (
    (currentUser && currentUser.id && currentUser?.attributes?.profile?.protectedData?.wishlist) ||
    []
  );
};

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const upsertWishlistItem = (currentUser, params) => {
  // Retrieve the current user's wishlist, which is expected to be an array of objects
  const wishlist = getWishlist(currentUser);

  // Destructure the params to get wishlistItem and wishlistName
  const { wishlistItem, wishlistName, listings = [] } = params;

  // Check if there's a wishlist with the given wishlistName
  const wishlistIndex = Array.isArray(wishlist)
    ? wishlist.findIndex(item => item.name === wishlistName)
    : -1;

  const selectedListing =
    isArrayLength(listings) && listings.find(l => l.id?.uuid === wishlistItem);

  const images = selectedListing ? selectedListing.images : [];
  const firstImage = isArrayLength(images)? images[0]?.attributes?.variants['scaled-medium']?.url : null;

  // If the wishlist name is found in the array
  if (wishlistIndex !== -1) {
    // Check if the wishlist item ID is already in the ids array; if not, add it
    if (!wishlist[wishlistIndex].ids.includes(wishlistItem)) {
      wishlist[wishlistIndex].ids.push(wishlistItem);
    }
  } else {
    // If the wishlist name is not found, create a new entry in the wishlist
    wishlist.push({
      name: wishlistName,
      ids: [wishlistItem],
      coverImage : firstImage
    });
  }

  return wishlist;
};
