/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  // {
  //   key: 'category',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'city-bikes', label: 'City bikes' },
  //     { option: 'electric-bikes', label: 'Electric bikes' },
  //     { option: 'mountain-bikes', label: 'Mountain bikes' },
  //     { option: 'childrens-bikes', label: "Children's bikes" },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Category',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Category',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Category',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a category.',
  //   },
  // },
  {
    key: 'putterIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: 'Putter',
      group: 'primary',
    },
    showConfig: {
      label: 'Putter',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a Putter.',
    },
  },
  {
    key: 'putterYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'putterBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: 'putterModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'putterHeadType',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Blade', label: 'Blade' },
      { option: 'Mallet', label: 'Mallet' },
      { option: 'Half Mallet', label: 'Half Mallet' },
      { option: 'Peripheral Weighted', label: 'Peripheral Weighted' },
      { option: 'Face-Balanced', label: 'Face-Balanced' },
      { option: 'Toe-Weighted', label: 'Toe-Weighted' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Head Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Head Type',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Head Type',
      isRequired: false,
      requiredMessage: 'You need to select Head Type.',
    },
  },
  {
    key: 'putterHeadLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Head Loft(°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Head Loft(°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Head Loft(°)',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'putterHeadWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Head Weight (g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Head Weight (g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Head Weight (g)',
      isRequired: false,
      requiredMessage: 'You need to select head weight.',
    },
  },
  {
    key: 'putterLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: 'putterHosel',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Plumber Neck', label: 'Plumber Neck' },
      { option: 'Double Bend', label: 'Double Bend' },
      { option: 'Offset', label: 'Offset' },
      { option: 'Slant Neck', label: 'Slant Neck' },
      { option: 'Center Shafted', label: 'Center Shafted' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Hosel',
      group: 'primary',
    },
    showConfig: {
      label: 'Hosel',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Hosel',
      isRequired: false,
      requiredMessage: 'You need to select Hosel.',
    },
  },
  {
    key: 'putterOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: 'putterToeHang',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Toe Hang (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Toe Hang (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Toe Hang (°)',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'putterLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'putterShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'putterGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },

  // This is the part for the Sand Wedge
  {
    key: 'sandwedgeIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: 'Sand Wedge',
      group: 'primary',
    },
    showConfig: {
      label: 'Sand Wedge',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a Sand Wedge.',
    },
  },
  {
    key: 'sandwedgeYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'sandwedgeBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: 'sandwedgeModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'sandwedgeLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: 'sandwedgeBounce',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Bounce (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Bounce (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Bounce (°)',
      isRequired: false,
      requiredMessage: 'You need to input Bounce.',
    },
  },
  {
    key: 'sandwedgeFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: 'sandwedgeGrind',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Full Sole', label: 'Full Sole' },
      { option: 'Mid-Sole', label: 'Mid-Sole' },
      { option: 'Low Bounce', label: 'Low Bounce' },
      { option: 'High Bounce', label: 'High Bounce' },
      { option: 'C-Grind', label: 'C-Grind' },
      { option: 'S-Grind', label: 'S-Grind' },
      { option: 'W-Grind', label: 'W-Grind' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Grind',
      group: 'primary',
    },
    showConfig: {
      label: 'Grind',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grind',
      isRequired: false,
      requiredMessage: 'You need to select a Grind.',
    },
  },
  {
    key: 'sandwedgeLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: 'sandwedgeLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: 'sandwedgeSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: 'sandwedgeOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: 'sandwedgeShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'sandwedgeGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the Gap/Approach Wedge
  {
    key: 'gapapproachwedgeIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: 'Gap/Approach Wedge',
      group: 'primary',
    },
    showConfig: {
      label: 'Gap/Approach Wedge',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a Gap/Approach Wedge.',
    },
  },
  {
    key: 'gapapproachwedgeYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'gapapproachwedgeBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: 'gapapproachwedgeModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'gapapproachwedgeLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: 'gapapproachwedgeBounce',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Bounce (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Bounce (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Bounce (°)',
      isRequired: false,
      requiredMessage: 'You need to input Bounce.',
    },
  },
  {
    key: 'gapapproachwedgeFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: 'gapapproachwedgeGrind',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Full Sole', label: 'Full Sole' },
      { option: 'Mid-Sole', label: 'Mid-Sole' },
      { option: 'Low Bounce', label: 'Low Bounce' },
      { option: 'High Bounce', label: 'High Bounce' },
      { option: 'C-Grind', label: 'C-Grind' },
      { option: 'S-Grind', label: 'S-Grind' },
      { option: 'W-Grind', label: 'W-Grind' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Grind',
      group: 'primary',
    },
    showConfig: {
      label: 'Grind',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grind',
      isRequired: false,
      requiredMessage: 'You need to select a Grind.',
    },
  },
  {
    key: 'gapapproachwedgeLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: 'gapapproachwedgeLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: 'gapapproachwedgeSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: 'gapapproachwedgeOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: 'gapapproachwedgeShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'gapapproachwedgeGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the Lob Wedge
  {
    key: 'lobwedgeIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: 'Lob Wedge',
      group: 'primary',
    },
    showConfig: {
      label: 'Lob Wedge',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a Lob Wedge.',
    },
  },
  {
    key: 'lobwedgeYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'lobwedgeBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: 'lobwedgeModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'lobwedgeLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: 'lobwedgeBounce',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Bounce (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Bounce (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Bounce (°)',
      isRequired: false,
      requiredMessage: 'You need to input Bounce.',
    },
  },
  {
    key: 'lobwedgeFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: 'lobwedgeGrind',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Full Sole', label: 'Full Sole' },
      { option: 'Mid-Sole', label: 'Mid-Sole' },
      { option: 'Low Bounce', label: 'Low Bounce' },
      { option: 'High Bounce', label: 'High Bounce' },
      { option: 'C-Grind', label: 'C-Grind' },
      { option: 'S-Grind', label: 'S-Grind' },
      { option: 'W-Grind', label: 'W-Grind' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Grind',
      group: 'primary',
    },
    showConfig: {
      label: 'Grind',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grind',
      isRequired: false,
      requiredMessage: 'You need to select a Grind.',
    },
  },
  {
    key: 'lobwedgeLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: 'lobwedgeLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: 'lobwedgeSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: 'lobwedgeOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: 'lobwedgeShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'lobwedgeGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the Pitching Wedge
  {
    key: 'pitchingwedgeIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: 'Pitching Wedge',
      group: 'primary',
    },
    showConfig: {
      label: 'Pitching Wedge',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a Pitching Wedge.',
    },
  },
  {
    key: 'pitchingwedgeYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'pitchingwedgeBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: 'pitchingwedgeModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'pitchingwedgeLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: 'pitchingwedgeBounce',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Bounce (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Bounce (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Bounce (°)',
      isRequired: false,
      requiredMessage: 'You need to input Bounce.',
    },
  },
  {
    key: 'pitchingwedgeFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: 'pitchingwedgeGrind',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Full Sole', label: 'Full Sole' },
      { option: 'Mid-Sole', label: 'Mid-Sole' },
      { option: 'Low Bounce', label: 'Low Bounce' },
      { option: 'High Bounce', label: 'High Bounce' },
      { option: 'C-Grind', label: 'C-Grind' },
      { option: 'S-Grind', label: 'S-Grind' },
      { option: 'W-Grind', label: 'W-Grind' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Grind',
      group: 'primary',
    },
    showConfig: {
      label: 'Grind',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grind',
      isRequired: false,
      requiredMessage: 'You need to select a Grind.',
    },
  },
  {
    key: 'pitchingwedgeLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: 'pitchingwedgeLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: 'pitchingwedgeSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: 'pitchingwedgeOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: 'pitchingwedgeShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'pitchingwedgeGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 1 Iron
  {
    key: '1ironIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '1 Iron',
      group: 'primary',
    },
    showConfig: {
      label: '1 Iron',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 1 Iron.',
    },
  },
  {
    key: '1ironYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '1ironBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '1ironModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '1ironLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '1ironFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '1ironLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '1ironLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '1ironSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '1ironOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: '1ironShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '1ironGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 2 Iron
  {
    key: '2ironIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '2 Iron',
      group: 'primary',
    },
    showConfig: {
      label: '2 Iron',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 2 Iron.',
    },
  },
  {
    key: '2ironYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '2ironBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '2ironModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '2ironLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '2ironFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '2ironLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '2ironLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '2ironSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '2ironOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: '2ironShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '2ironGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 3 Iron
  {
    key: '3ironIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '3 Iron',
      group: 'primary',
    },
    showConfig: {
      label: '3 Iron',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 3 Iron.',
    },
  },
  {
    key: '3ironYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '3ironBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '3ironModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '3ironLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '3ironFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '3ironLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '3ironLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '3ironSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '3ironOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: '3ironShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '3ironGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 4 Iron
  {
    key: '4ironIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '4 Iron',
      group: 'primary',
    },
    showConfig: {
      label: '4 Iron',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 4 Iron.',
    },
  },
  {
    key: '4ironYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '4ironBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '4ironModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '4ironLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '4ironFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '4ironLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '4ironLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '4ironSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '4ironOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: '4ironShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '4ironGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 5 Iron
  {
    key: '5ironIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '5 Iron',
      group: 'primary',
    },
    showConfig: {
      label: '5 Iron',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 5 Iron.',
    },
  },
  {
    key: '5ironYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '5ironBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '5ironModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '5ironLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '5ironFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '5ironLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '5ironLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '5ironSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '5ironOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: '5ironShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '5ironGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 6 Iron
  {
    key: '6ironIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '6 Iron',
      group: 'primary',
    },
    showConfig: {
      label: '6 Iron',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 6 Iron.',
    },
  },
  {
    key: '6ironYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '6ironBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '6ironModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '6ironLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '6ironFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '6ironLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '6ironLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '6ironSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '6ironOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: '6ironShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '6ironGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 7 Iron
  {
    key: '7ironIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '7 Iron',
      group: 'primary',
    },
    showConfig: {
      label: '7 Iron',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 7 Iron.',
    },
  },
  {
    key: '7ironYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '7ironBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '7ironModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '7ironLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '7ironFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '7ironLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '7ironLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '7ironSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '7ironOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: '7ironShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '7ironGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 8 Iron
  {
    key: '8ironIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '8 Iron',
      group: 'primary',
    },
    showConfig: {
      label: '8 Iron',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 8 Iron.',
    },
  },
  {
    key: '8ironYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '8ironBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '8ironModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '8ironLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '8ironFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '8ironLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '8ironLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '8ironSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '8ironOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: '8ironShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '8ironGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 9 Iron
  {
    key: '9ironIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '9 Iron',
      group: 'primary',
    },
    showConfig: {
      label: '9 Iron',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 9 Iron.',
    },
  },
  {
    key: '9ironYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '9ironBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '9ironModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '9ironLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '9ironFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '9ironLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '9ironLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '9ironSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '9ironOffset',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Offset (mm)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Offset (mm)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Offset (mm)',
      isRequired: false,
      requiredMessage: 'You need to select Offset.',
    },
  },
  {
    key: '9ironShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '9ironGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 3 Hybrid
  {
    key: '3hybridIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '3 Hybrid',
      group: 'primary',
    },
    showConfig: {
      label: '3 Hybrid',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 3 Hybrid.',
    },
  },
  {
    key: '3hybridYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '3hybridBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '3hybridModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '3hybridLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '3hybridFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '3hybridLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '3hybridLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '3hybridSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '3hybridShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '3hybridGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 4 Hybrid
  {
    key: '4hybridIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '4 Hybrid',
      group: 'primary',
    },
    showConfig: {
      label: '4 Hybrid',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 4 Hybrid.',
    },
  },
  {
    key: '4hybridYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '4hybridBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '4hybridModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '4hybridLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '4hybridFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '4hybridLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '4hybridLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '4hybridSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '4hybridShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '4hybridGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 5 Hybrid
  {
    key: '5hybridIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '5 Hybrid',
      group: 'primary',
    },
    showConfig: {
      label: '5 Hybrid',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 5 Hybrid.',
    },
  },
  {
    key: '5hybridYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '5hybridBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '5hybridModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '5hybridLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '5hybridFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '5hybridLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '5hybridLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '5hybridSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '5hybridShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '5hybridGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 6 Hybrid
  {
    key: '6hybridIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '6 Hybrid',
      group: 'primary',
    },
    showConfig: {
      label: '6 Hybrid',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 6 Hybrid.',
    },
  },
  {
    key: '6hybridYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '6hybridBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '6hybridModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '6hybridLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '6hybridFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '6hybridLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '6hybridLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '6hybridSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '6hybridShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '6hybridGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 7 Hybrid
  {
    key: '7hybridIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '7 Hybrid',
      group: 'primary',
    },
    showConfig: {
      label: '7 Hybrid',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 7 Hybrid.',
    },
  },
  {
    key: '7hybridYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '7hybridBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '7hybridModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '7hybridLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '7hybridFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '7hybridLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '7hybridLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '7hybridSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '7hybridShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '7hybridGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 3 Wood
  {
    key: '3woodIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '3 Wood',
      group: 'primary',
    },
    showConfig: {
      label: '3 Wood',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 3 Wood.',
    },
  },
  {
    key: '3woodYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '3woodBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '3woodModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '3woodLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '3woodFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '3woodLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '3woodLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '3woodSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '3woodHeadSize',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Head Size(cc)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Head Size(cc)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Head Size(cc)',
      isRequired: false,
      requiredMessage: 'You need to select Head Size(cc)',
    },
  },
  {
    key: '3woodShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '3woodGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 3HL Wood
  {
    key: '3hlwoodIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '3HL Wood',
      group: 'primary',
    },
    showConfig: {
      label: '3HL Wood',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 3HL Wood.',
    },
  },
  {
    key: '3hlwoodYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '3hlwoodBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '3hlwoodModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '3hlwoodLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '3hlwoodFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '3hlwoodLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '3hlwoodLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '3hlwoodSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '3hlwoodHeadSize',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Head Size(cc)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Head Size(cc)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Head Size(cc)',
      isRequired: false,
      requiredMessage: 'You need to select Head Size(cc)',
    },
  },
  {
    key: '3hlwoodShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '3hlwoodGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 5 Wood
  {
    key: '5woodIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '5 Wood',
      group: 'primary',
    },
    showConfig: {
      label: '5 Wood',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 5 Wood.',
    },
  },
  {
    key: '5woodYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '5woodBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '5woodModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '5woodLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '5woodFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '5woodLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '5woodLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '5woodSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '5woodHeadSize',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Head Size(cc)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Head Size(cc)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Head Size(cc)',
      isRequired: false,
      requiredMessage: 'You need to select Head Size(cc)',
    },
  },
  {
    key: '5woodShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '5woodGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 7 Wood
  {
    key: '7woodIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '7 Wood',
      group: 'primary',
    },
    showConfig: {
      label: '7 Wood',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 7 Wood.',
    },
  },
  {
    key: '7woodYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '7woodBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '7woodModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '7woodLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '7woodFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '7woodLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '7woodLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '7woodSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '7woodHeadSize',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Head Size(cc)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Head Size(cc)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Head Size(cc)',
      isRequired: false,
      requiredMessage: 'You need to select Head Size(cc)',
    },
  },
  {
    key: '7woodShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '7woodGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the Heavenwood
  {
    key: 'heavenwoodIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: 'Heavenwood',
      group: 'primary',
    },
    showConfig: {
      label: 'Heavenwood',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a Heavenwood.',
    },
  },
  {
    key: 'heavenwoodYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'heavenwoodBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: 'heavenwoodModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'heavenwoodLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: 'heavenwoodFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: 'heavenwoodLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: 'heavenwoodLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: 'heavenwoodSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: 'heavenwoodHeadSize',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Head Size(cc)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Head Size(cc)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Head Size(cc)',
      isRequired: false,
      requiredMessage: 'You need to select Head Size(cc)',
    },
  },
  {
    key: 'heavenwoodShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'heavenwoodGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 9 Wood
  {
    key: '9woodIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '9 Wood',
      group: 'primary',
    },
    showConfig: {
      label: '9 Wood',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 9 Wood.',
    },
  },
  {
    key: '9woodYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '9woodBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '9woodModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '9woodLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '9woodFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '9woodLength',
    scope: 'public',
    schemaType: 'long',
    filterConfig: {
      indexForSearch: true,
      label: 'Length (in)',
      group: 'primary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '9woodLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '9woodSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '9woodHeadSize',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Head Size(cc)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Head Size(cc)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Head Size(cc)',
      isRequired: false,
      requiredMessage: 'You need to select Head Size(cc)',
    },
  },
  {
    key: '9woodShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '9woodGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the 11 Wood
  {
    key: '11woodIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: '11 Wood',
      group: 'primary',
    },
    showConfig: {
      label: '11 Wood',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a 11 Wood.',
    },
  },
  {
    key: '11woodYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: '11woodBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: '11woodModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '11woodLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: '11woodFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: '11woodLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: '11woodLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: '11woodSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: '11woodHeadSize',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Head Size(cc)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Head Size(cc)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Head Size(cc)',
      isRequired: false,
      requiredMessage: 'You need to select Head Size(cc)',
    },
  },
  {
    key: '11woodShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: '11woodGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the Driver
  {
    key: 'driverIn',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: false,
      label: 'Driver',
      group: 'primary',
    },
    showConfig: {
      label: 'Driver',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'In My Bag',
      isRequired: false,
      requiredMessage: 'You need to select a Driver.',
    },
  },
  {
    key: 'driverYear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '2000', label: '2000' },
      { option: '2001', label: '2001' },
      { option: '2002', label: '2002' },
      { option: '2003', label: '2003' },
      { option: '2004', label: '2004' },
      { option: '2005', label: '2005' },
      { option: '2006', label: '2006' },
      { option: '2007', label: '2007' },
      { option: '2008', label: '2008' },
      { option: '2009', label: '2009' },
      { option: '2010', label: '2010' },
      { option: '2011', label: '2011' },
      { option: '2012', label: '2012' },
      { option: '2013', label: '2013' },
      { option: '2014', label: '2014' },
      { option: '2015', label: '2015' },
      { option: '2016', label: '2016' },
      { option: '2017', label: '2017' },
      { option: '2018', label: '2018' },
      { option: '2019', label: '2019' },
      { option: '2020', label: '2010' },
      { option: '2021', label: '2021' },
      { option: '2022', label: '2022' },
      { option: '2023', label: '2023' },
      { option: '2024', label: '2024' },
      { option: '2025', label: '2025' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Year',
      isRequired: false,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'driverBrand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Axis_1', label: 'Axis 1' },
      { option: 'Bettinardi', label: 'Bettinardi' },
      { option: 'Callaway', label: 'Callaway' },
      { option: 'Cleveland', label: 'Cleveland' },
      { option: 'COBRA', label: 'COBRA' },
      { option: 'Evnroll', label: 'Evnroll' },
      { option: 'Good_Good_Golf', label: 'Good Golf' },
      { option: 'LA_Golf', label: 'LA Golf' },
      { option: 'L-A-B-_Golf', label: 'L.A.B. Golf' },
      { option: 'Mizuno', label: 'Mizuno' },
      { option: 'Nike', label: 'Nike' },
      { option: 'Odyssey', label: 'Odyssey' },
      { option: 'PING', label: 'PING' },
      { option: 'PXG', label: 'PXG' },
      { option: 'Robin_Golf', label: 'Robin Golf' },
      { option: 'Scotty_Cameron', label: 'Scotty Cameron' },
      { option: 'SeeMore', label: 'SeeMore' },
      { option: 'SIK', label: 'SIK' },
      { option: 'Srixon', label: 'Srixon' },
      { option: 'TaylorMade', label: 'TaylorMade' },
      { option: 'Titleist', label: 'Titleist' },
      { option: 'Tommy_Armour', label: 'Tommy Armour' },
      { option: 'Top_Flite', label: 'Top Flite' },
      { option: 'Tour_Edge', label: 'Tour Edge' },
      { option: 'U-S-_Kids_Golf', label: 'U.S. Kids Golf' },
      { option: 'Wilson', label: 'Wilson' },
      { option: 'XXIO_Golf', label: 'XXIO Golf' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: 'driverModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Model',
      group: 'secondary',
    },
    showConfig: {
      label: 'Model',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Model (Required)',
      isRequired: true,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'driverLoft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Loft (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Loft (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Loft (°)',
      isRequired: false,
      requiredMessage: 'You need to input Loft.',
    },
  },
  {
    key: 'driverFlex',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Extra_Stiff', label: 'Extra Stiff' },
      { option: 'Junior', label: 'Junior' },
      { option: 'Rugular-_y1z2_inch', label: 'Rugular, +1/2 inch' },
      { option: 'Stiff-_-1z2_inch', label: 'Stiff, -1/2 inch' },
      { option: 'Stiff-_y1_inch', label: 'Stiff, +1 inch' },
      { option: 'Stiff-_y1z2_inch', label: 'Stiff, +1/2 inch' },
      { option: 'Regular', label: 'Regular' },
      { option: 'Senior', label: 'Senior' },
      { option: 'Women', label: "Women's" },
      { option: 'Stiff', label: 'Stiff' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Flex',
      group: 'secondary',
    },
    showConfig: {
      label: 'Flex',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Flex',
      isRequired: false,
      requiredMessage: 'You need to input Flex.',
    },
  },
  {
    key: 'driverLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Length (in)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Length (in)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Length (in)',
      isRequired: false,
      requiredMessage: 'You need to select length.',
    },
  },
  {
    key: 'driverLieAngle',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Lie Angle (°)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Lie Angle (°)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Lie Angle (°)',
      isRequired: false,
      requiredMessage: 'You need to input Lie Angle.',
    },
  },
  {
    key: 'driverSwingWeight',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Swing Weight(g)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Swing Weight(g)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Swing Weight(g)',
      isRequired: false,
      requiredMessage: 'You need to input Swing Weight',
    },
  },
  {
    key: 'driverHeadSize',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Head Size(cc)',
      group: 'secondary',
    },
    showConfig: {
      label: 'Head Size(cc)',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Head Size(cc)',
      isRequired: false,
      requiredMessage: 'You need to select Head Size(cc)',
    },
  },
  {
    key: 'driverShaft',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Shaft',
      group: 'secondary',
    },
    showConfig: {
      label: 'Shaft',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Shaft Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  {
    key: 'driverGrip',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Grip',
      group: 'secondary',
    },
    showConfig: {
      label: 'Grip',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Grip Description',
      isRequired: false,
      requiredMessage: 'You need to select a model.',
    },
  },
  // This is the part for the Driver
  {
    key: 'golfbagName',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Golf Bag',
      group: 'secondary',
    },
    showConfig: {
      label: 'Golf Bag',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Brand, Name/Model',
      isRequired: false,
      requiredMessage: 'You need to write bag detail.',
    },
  },

  // For the handness and condition field

  {
    key: 'isRight',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: true,
      label: 'Handedness',
      group: 'primary',
    },
    showConfig: {
      label: 'Driver',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Right',
      isRequired: false,
      requiredMessage: 'You need to select a Handedness.',
    },
  },
  {
    key: 'isLeft',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: true,
      label: 'Handedness',
      group: 'primary',
    },
    showConfig: {
      label: 'Left',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Left',
      isRequired: false,
      requiredMessage: 'You need to select a Handedness.',
    },
  },
  {
    key: 'clubCondition',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'likeNew', label: 'Like New' },
      { option: 'Good', label: 'Good' },
      { option: 'Fair', label: 'Fair' },
      { option: 'SignificantWeaTear', label: 'Significant Wear & Tear' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Condition',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: false,
    },
    saveConfig: {
      label: '',
      placeholderMessage: '(Required)',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },

  // {
  //   key: 'handedness',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'right', label: 'Righty' },
  //     { option: 'left', label: 'Lefty' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter', //SelectMultipleFilter  SelectSingleFilter
  //     label: 'Handedness',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Category',
  //     isDetail: false,
  //   },
  //   saveConfig: {
  //     label: '',
  //     placeholderMessage: 'Handedness',
  //     isRequired: false,
  //     requiredMessage: 'You need to select a handedness.',
  //   },
  // },

  // {
  //   key: 'tire',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '29', label: '29' },
  //     { option: '28', label: '28' },
  //     { option: '27', label: '27' },
  //     { option: '26', label: '26' },
  //     { option: '24', label: '24' },
  //     { option: '20', label: '20' },
  //     { option: '18', label: '18' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Tire size',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Tire size',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Tire size',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a tire size.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },
  // {
  //   key: 'stringexam',
  //   scope: 'public',
  //   schemaType: 'text',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  //   {
  //     listingType: 'daily-booking',
  //     label: 'Daily booking',
  //     transactionType: {
  //       process: 'default-booking',
  //       alias: 'default-booking/release-1',
  //       unitType: 'day',
  //     },
  //     defaultListingFields: {
  //       location: true,
  //       payoutDetails: true,
  //     },
  //   },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  //   defaultListingFields: {
  //     shipping: true,
  //     pickup: true,
  //     payoutDetails: true,
  //   },
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
